<template>
  <v-container fluid>
    <!-- filters -->
    <v-row no-gutters>
      <v-col cols="4">
        <p class="text--secondary mb-1 ml-1">Buscar por nome, acrônimo ou nome do banco</p>
        <v-autocomplete
          v-model="selected"
          class="mr-4"
          label="Buscar cliente"
          :items.sync="foundAccounts"
          :search-input.sync="search"
          outlined
          append-icon="search"
        />
      </v-col>
      <v-col cols="2">
        <div class="mx-2">
          <p class="text--secondary mb-1 ml-1">Data Inicial:</p>
          <date-picker v-model="initialDate" :outline="true" :date.sync="initialDate" />
        </div>
      </v-col>
      <v-col cols="2">
        <div class="mx-2">
          <p class="text--secondary mb-1 ml-1">Data Final:</p>
          <date-picker v-model="endDate" :outline="true" :date.sync="endDate" />
        </div>
      </v-col>
      <v-col cols="4">
        <v-card-text class="my-2">
          <v-btn block :disabled="!selected || !initialDate || !endDate || executing" x-large color="primary" class="mt-1" @click="getPdvCouponsMetrics"
            ><v-icon class="mr-2">search</v-icon> Gerar Relatórios</v-btn
          >
        </v-card-text>
      </v-col>
    </v-row>

    <!-- loading -->
    <v-progress-linear v-if="loading" class="my-4" color="primary accent-4" indeterminate rounded height="6" />

    <!-- reports -->
    <div v-if="!loading && generateReports">
      <!-- total/billing -->
      <v-row no-gutters class="py-2">
        <v-col cols="6">
          <v-card tile class="mr-4 elevation-8">
            <h2 class="pa-4"><v-icon class="mr-2" large>mdi-ticket-outline</v-icon> TOTAL DE CUPONS NO PERÍODO</h2>
            <v-card-text>
              <h1>{{ metrics.totalCoupons }}</h1>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card tile class="ml-4 elevation-8">
            <h2 class="pa-4"><v-icon class="mr-2 pb-2" large>mdi-ticket-percent-outline</v-icon> TOTAL DE FATURAMENTO NO PERÍODO</h2>
            <v-card-text>
              <h1>{{ parseCurrency(metrics.totalCouponsBillin) }}</h1>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- first/last coupon -->
      <v-row no-gutters class="py-2">
        <v-col cols="6">
          <v-card tile class="mr-4 elevation-8">
            <h2 class="pa-4"><v-icon class="mr-2" large>mdi-ticket</v-icon> PRIMEIRO CUPOM GERADO</h2>
            <v-card-text>
              <v-list subheader>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>ID da Requisição</v-list-item-title>
                    <v-list-item-subtitle>{{ metrics.firstCoupon.requestId }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>ID Cupom</v-list-item-title>
                    <v-list-item-subtitle>{{ metrics.firstCoupon.requestData.coupon }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Valor</v-list-item-title>
                    <v-list-item-subtitle>{{ 'R$ ' + metrics.firstCoupon.requestData.total_value_with_discount }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Data</v-list-item-title>
                    <v-list-item-subtitle>{{ metrics.firstCoupon.requestData.date }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-spacer />
              <v-btn color="primary" outlined block @click="openDetails('details', metrics.firstCoupon)"><v-icon class="mr-1">mdi-menu</v-icon> Detalhes</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card tile class="ml-4 elevation-8">
            <h2 class="pa-4"><v-icon class="mr-2" large>mdi-ticket</v-icon> ÚLTIMO CUPOM GERADO</h2>
            <v-card-text>
              <v-list subheader>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>ID da Requisição</v-list-item-title>
                    <v-list-item-subtitle>{{ metrics.lastCoupon.requestId }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>ID Cupom</v-list-item-title>
                    <v-list-item-subtitle>{{ metrics.lastCoupon.requestData.coupon }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Valor</v-list-item-title>
                    <v-list-item-subtitle>{{ 'R$ ' + metrics.lastCoupon.requestData.total_value_with_discount }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Data</v-list-item-title>
                    <v-list-item-subtitle>{{ metrics.lastCoupon.requestData.date }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-spacer />
              <v-btn color="primary" outlined block @click="openDetails('details', metrics.lastCoupon)"><v-icon class="mr-1">mdi-menu</v-icon> Detalhes</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- datatables -->
      <v-row no-gutters class="py-4">
        <v-col cols="6">
          <v-card tile class="mr-4 elevation-8">
            <h2 class="pa-4"><v-icon class="mr-2 pb-2" large>mdi-ticket-confirmation-outline</v-icon> ÚLTIMOS CUPONS RECEBIDOS</h2>
            <v-data-table :items="metrics.lastReceivedCoupons" :headers="headers" :footer-props="{ itemsPerPageOptions: [5, 10, 20] }">
              <!-- eslint-disable-next-line -->
              <template v-slot:item.processed="{ item }">
                <v-icon class="mr-2" x-small :color="item.processed ? 'success' : 'error'">mdi-brightness-1</v-icon>
                {{ item.processed ? 'Sim' : 'Não' }}
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.requestData.total_value_with_discount="{ item }">
                {{ parseCurrency(item.requestData.total_value_with_discount) }}
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.details="{ item }">
                <v-btn color="primary" outlined small @click="openDetails('details', item)"><v-icon small class="mr-1">mdi-menu</v-icon> Detalhes</v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card tile class="ml-4 elevation-8">
            <h2 class="pa-4"><v-icon class="mr-2 pb-1" large>mdi-alert-circle-outline</v-icon> CUPONS DUPLICADOS NO PERÍODO</h2>
            <v-data-table :items="metrics.duplicatedCoupons" :headers="headersDuplicated" :footer-props="{ itemsPerPageOptions: [5, 10, 20] }">
              <!-- eslint-disable-next-line -->
              <template v-slot:item.details="{ item }">
                <v-btn color="primary" outlined small @click="openDetails('duplicated', item)"><v-icon small class="mr-1">mdi-menu</v-icon> Detalhes</v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <!-- dialogs -->
      <coupon-details :dialog="couponDetailsDialog" :coupon="selectedCoupon" @close="close" />
      <duplicated-coupon-details :dialog="duplicatedCouponDetailsDialog" :coupon="selectedCoupon" @close="close" />
    </div>
  </v-container>
</template>

<script>
import { QUERY_QUICK_ACCESS } from '@/modules/home/graphql'
import { QUERY_GET_PDV_COUPONS_METRICS } from '@/modules/deployment/graphql'

export default {
  components: {
    DatePicker: () => import('@/components/calendar/DatePicker'),
    CouponDetails: () => import('@/modules/deployment/components/CouponDetails'),
    DuplicatedCouponDetails: () => import('@/modules/deployment/components/DuplicatedCouponDetails')
  },
  data: () => ({
    search: '',
    loading: false,
    generateReports: false,
    executing: false,
    selected: '',
    foundAccounts: [],
    initialDate: '',
    endDate: '',
    couponDetailsDialog: false,
    duplicatedCouponDetailsDialog: false,
    selectedCoupon: null,
    metrics: {
      totalCoupons: 0,
      totalCouponsBillin: 0,
      lastReceivedCoupons: [],
      duplicatedCoupons: [],
      firstCoupon: {},
      lastCoupon: {}
    }
  }),
  computed: {
    headers() {
      return [
        {
          text: 'ID Cupom',
          value: 'requestData.coupon'
        },
        {
          text: 'Data',
          value: 'requestData.date'
        },
        {
          text: 'ID Loja',
          value: 'unitId'
        },
        {
          text: 'Processado',
          value: 'processed'
        },
        {
          text: 'Valor',
          value: 'requestData.total_value_with_discount'
        },
        {
          text: '',
          value: 'details'
        }
      ]
    },
    headersDuplicated() {
      return [
        {
          text: 'ID Cupom',
          value: '_id'
        },
        {
          text: 'Quantidade de duplicatas',
          value: 'count'
        },
        {
          text: '',
          value: 'details'
        }
      ]
    }
  },
  watch: {
    search(value) {
      if (value && value.trim() !== '') this.getAccounts(value.toLowerCase())
    }
  },
  methods: {
    async getAccounts(value) {
      setTimeout(async () => {
        const { data } = await this.$apollo.query({
          query: QUERY_QUICK_ACCESS,
          variables: { param: value }
        })
        this.foundAccounts = data.quickAccess.map(item => item.db_name)
      }, 1500)
    },
    async getPdvCouponsMetrics() {
      this.executing = true
      const datesDiff = this.$moment(this.endDate).diff(this.$moment(this.initialDate), 'days')
      if (+datesDiff > 90 || +datesDiff < -90) {
        this.executing = false
        return this.$snackbar({ message: `Período entre as datas não pode ser maior que 90 dias, Período: ${datesDiff} dia(s)`, snackbarColor: '#F44336' })
      }

      try {
        this.generateReports = false
        this.loading = true
        const { data } = await this.$apollo.query({
          query: QUERY_GET_PDV_COUPONS_METRICS,
          fetchPolicy: 'network-only',
          variables: {
            db_name: this.selected,
            start_date: this.initialDate,
            end_date: this.endDate
          }
        })

        this.metrics.totalCoupons = +data?.adminGetPdvCouponsMetrics?.totalCoupons
        this.metrics.totalCouponsBillin = data?.adminGetPdvCouponsMetrics?.totalCouponsBillin.toFixed(2)
        this.metrics.lastReceivedCoupons = data?.adminGetPdvCouponsMetrics?.lastReceivedCoupons
        this.metrics.duplicatedCoupons = data?.adminGetPdvCouponsMetrics?.duplicatedCoupons
        this.metrics.firstCoupon = data?.adminGetPdvCouponsMetrics?.firstCoupon
        this.metrics.lastCoupon = data?.adminGetPdvCouponsMetrics?.lastCoupon
        this.generateReports = true
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao gerar métricas', snackbarColor: '#F44336' })
      }
      setTimeout(() => {
        this.loading = false
        this.executing = false
      }, 1000)
    },
    parseCurrency(value) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      })

      return formatter.format(value)
    },
    openDetails(flag, coupon) {
      this.selectedCoupon = coupon
      if (flag === 'duplicated') this.duplicatedCouponDetailsDialog = true
      else if (flag === 'details') this.couponDetailsDialog = true
    },
    close() {
      this.couponDetailsDialog = false
      this.duplicatedCouponDetailsDialog = false
      setTimeout(() => {
        this.selectedCoupon = null
      }, 500)
    }
  }
}
</script>
