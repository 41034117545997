import gql from 'graphql-tag'

export const QUERY_GET_COMMANDS = gql`
  query {
    adminGetDeploymentCommands {
      command
      name
      description
      permissions
      tag
      createdAt
      params {
        name
        type
        required
        default
        description
      }
    }
  }
`
export const MUTATION_EXECUTE_DEPLOYMENT_COMMAND = gql`
  mutation($db_name: String!, $command: String!, $params: JSONObject!) {
    adminExecuteDeploymentCommand(db_name: $db_name, command: $command, params: $params)
  }
`
export const QUERY_GET_PDV_COUPONS_METRICS = gql`
  query($db_name: String!, $start_date: String!, $end_date: String!) {
    adminGetPdvCouponsMetrics(db_name: $db_name, start_date: $start_date, end_date: $end_date) {
      totalCoupons
      totalCouponsBillin
      lastReceivedCoupons {
        _id
        requestId
        unitId
        processed
        date
        requestData {
          client_id
          operator_id
          coupon
          total_value
          total_value_with_discount
          date
          time
          items
        }
        customData {
          endpointType
          version
        }
      }
      duplicatedCoupons {
        _id
        uniqueIds
        count
      }
      firstCoupon {
        _id
        requestId
        unitId
        processed
        date
        requestData {
          client_id
          operator_id
          coupon
          total_value
          total_value_with_discount
          date
          time
          items
        }
        customData {
          endpointType
          version
        }
      }
      lastCoupon {
        _id
        requestId
        unitId
        processed
        date
        requestData {
          client_id
          operator_id
          coupon
          total_value
          total_value_with_discount
          date
          time
          items
        }
        customData {
          endpointType
          version
        }
      }
    }
  }
`
export const MUTATION_IMPORT_ERP_CLIENTS = gql`
  mutation($db_name: String!, $command: String!, $file: Upload!, $user_id: String!) {
    adminExecuteImportErpClients(db_name: $db_name, command: $command, file: $file, user_id: $user_id)
  }
`
export const QUERY_GET_GOOGLE_USER = gql`
  query($session_id: String) {
    getLoggedUser(session_id: $session_id) {
      _id
      name
      login
    }
  }
`
